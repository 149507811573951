import { type ReactNode } from 'react';

import {
  type DtoFeaturedItem,
  type DtoGamePack,
  type DtoTag,
  EnumsFeaturedItemType,
} from '@lp-lib/api-service-client/public';

import { GamePackCoverImage } from '../../../src/components/GamePackCover/GamePackCoverPres';
import { fromDTOGamePack } from '../../../src/utils/api-dto';
import { GamePackFeaturedCard } from '../GamePack/GamePackFeaturedCard';
import { TagFeaturedCard, TagFeaturedSlice } from '../Tag/TagFeaturedCard';
import { FeaturedPlanAdCard, FeaturedPlanAdSlice } from './FeaturedPlanAd';

export interface FeaturedCardProps {
  item: DtoFeaturedItem;
  index: number;
  onToggleAutoplay: (val: boolean) => void;
  onGamePackClick?: (pack: DtoGamePack) => void;
  renderGamePackAction?: (pack: DtoGamePack) => ReactNode;
  onGamePackViewed?: (pack: DtoGamePack) => void;
  onTagClick?: (tag: DtoTag) => void;
  onTagViewed?: (tag: DtoTag) => void;
}

export function FeaturedCard(props: FeaturedCardProps) {
  const {
    item,
    onGamePackClick,
    renderGamePackAction,
    onGamePackViewed,
    onTagClick,
    onTagViewed,
  } = props;

  switch (item.type) {
    case EnumsFeaturedItemType.FeaturedItemTypeGamePack:
      const pack = item.gamePack;
      if (!pack) return null;
      return (
        <GamePackFeaturedCard
          pack={fromDTOGamePack(pack)}
          onToggleAutoplay={props.onToggleAutoplay}
          onClick={onGamePackClick ? () => onGamePackClick(pack) : undefined}
          action={renderGamePackAction ? renderGamePackAction(pack) : undefined}
          onViewed={onGamePackViewed ? () => onGamePackViewed(pack) : undefined}
        />
      );
    case EnumsFeaturedItemType.FeaturedItemTypeTag:
      const tag = props.item.tag;
      if (!tag) return null;
      return (
        <TagFeaturedCard
          tag={tag}
          onClick={onTagClick ? () => onTagClick(tag) : undefined}
          onViewed={onTagViewed ? () => onTagViewed(tag) : undefined}
        />
      );
    case EnumsFeaturedItemType.FeaturedItemTypePlanAd:
      const product = item.product;
      if (!product) return null;
      return <FeaturedPlanAdCard product={product} />;
    default:
      return null;
  }
}

export interface FeaturedSliceProps {
  item: DtoFeaturedItem;
  index: number;
}

export function FeaturedSlice(props: FeaturedSliceProps) {
  switch (props.item.type) {
    case EnumsFeaturedItemType.FeaturedItemTypeGamePack:
      const pack = props.item.gamePack;
      if (!pack) return null;
      return (
        <GamePackCoverImage
          pack={fromDTOGamePack(pack)}
          className='absolute w-full h-full object-cover'
          alt={pack.name}
        />
      );
    case EnumsFeaturedItemType.FeaturedItemTypeTag:
      const tag = props.item.tag;
      if (!tag) return null;
      return <TagFeaturedSlice tag={tag} />;
    case EnumsFeaturedItemType.FeaturedItemTypePlanAd:
      return <FeaturedPlanAdSlice />;
    default:
      return null;
  }
}
